import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@mui/material"
import {
  SocialIconButton,
  TextFieldWrapper,
} from "components/authentication/StyledComponents"
import FlexBox from "components/FlexBox"
import LightTextField from "components/LightTextField"
import { H1, H3, Paragraph, Small } from "components/Typography"
import { useFormik } from "formik"
import useAuth from "hooks/useAuth"

import { useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"

const Login = () => {
  const { login } = useAuth()
  console.log(login)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()
  const initialValues = {
    email: "",
    password: "",
    submit: null,
    remember: true,
  } // form field value validation schema

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("이메일 주소를 다시 확인해주세요.")
      .max(255)
      .required("필수 입력입니다."),
    password: Yup.string()
      .min(6, "비밀번호를 다시 확인해주세요.")
      .required("필수 입력입니다."),
  })
  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        setLoading(true)
        console.log(values)
        login(values.email, values.password)
          .then(() => {
            setLoading(false)
            toast.success("TROSAR플랫폼에 오신걸 환영 합니다.")
            navigate("/")
          })
          .catch((error) => {
            setError(error.message)
            setLoading(false)
          })
      },
    })
  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: {
          sm: "100%",
        },
      }}
    >
      <Card
        sx={{
          padding: 4,
          maxWidth: 600,
          boxShadow: 1,
        }}
      >
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Box width={38} mb={1}>
            <img src="/static/logo/logo.svg" width="100%" alt="Uko Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            TROSAR 포털
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{
              width: "100%",
            }}
          >
            <FlexBox justifyContent="space-between" flexWrap="wrap">
              <TextFieldWrapper
                sx={{
                  mt: 1,
                  width: "100%",
                }}
              >
                <Paragraph fontWeight={600} mb={1}>
                  Email
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="email"
                  type="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ""}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </TextFieldWrapper>

              <TextFieldWrapper
                sx={{
                  mt: 1,
                  width: "100%",
                }}
              >
                <Paragraph fontWeight={600} mb={1}>
                  Password
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>
            </FlexBox>
            <Small margin="auto" mt={3} color="text.disabled">
              TEST계정 email : demo@example.com / password: qqq111
            </Small>
            <FlexBox mt={2} alignItems="center" justifyContent="space-between">
              <FormControlLabel
                control={
                  <Switch
                    name="remember"
                    checked={values.remember}
                    onChange={handleChange}
                  />
                }
                label="Remember Me"
                sx={{
                  "& .MuiTypography-root": {
                    fontWeight: 600,
                  },
                }}
              />
              {/* <Link to="/forget-password"> */}
              <Small
                color="secondary.red"
                onClick={() => toast.error("관리자에게 문의해 주세요")}
              >
                비밀번호 찾기
              </Small>
              {/* </Link> */}
            </FlexBox>

            {error && (
              <FormHelperText
                error
                sx={{
                  mt: 2,
                  fontSize: 13,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </FormHelperText>
            )}

            <Box
              sx={{
                mt: 4,
              }}
            >
              {loading ? (
                <LoadingButton loading fullWidth variant="contained">
                  로그인
                </LoadingButton>
              ) : (
                <Button fullWidth type="submit" variant="contained">
                  로그인
                </Button>
              )}
            </Box>
          </form>
          {/* <Divider
            sx={{
              my: 3,
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <H3 color="text.disabled" px={1}>
              Or
            </H3>
          </Divider> */}
          {/* 
          <SocialIconButton
            onClick={loginWithGoogle}
            startIcon={
              <GoogleIcon
                sx={{
                  mr: 1,
                }}
              />
            }
          >
            Sign in with Google
          </SocialIconButton>
          <SocialIconButton
            onClick={loginWithFacebook}
            startIcon={
              <FacebookIcon
                sx={{
                  mr: 1,
                }}
              />
            }
          >
            Sign in with Facebook
          </SocialIconButton> */}

          <Small margin="auto" mt={3} color="text.disabled">
            사용중인 계정이 없다면 서비스 가입이 필요합니다.{" "}
            <Link to="/register">
              <Small color="primary.main">가입하기</Small>
            </Link>
          </Small>
        </FlexBox>
      </Card>
    </FlexBox>
  )
}

export default Login
