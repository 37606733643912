import { SvgIcon } from "@mui/material";

const ThemeIcon = props => {
  return <SvgIcon viewBox="0 0 20 20" {...props}>
      <path d="M9.99976 16.522C13.6016 16.522 16.5215 13.6021 16.5215 10.0002C16.5215 6.3984 13.6016 3.47852 9.99976 3.47852C6.39791 3.47852 3.47803 6.3984 3.47803 10.0002C3.47803 13.6021 6.39791 16.522 9.99976 16.522Z" fill="#FFC107" />
      <path d="M9.99973 2.6087C10.2399 2.6087 10.4345 2.41402 10.4345 2.1739V0.434796C10.4345 0.194674 10.2399 0 9.99973 0C9.75961 0 9.56494 0.194674 9.56494 0.434796V2.17394C9.56498 2.41402 9.75961 2.6087 9.99973 2.6087Z" fill="#FFD54F" />
      <path d="M9.99973 17.3906C9.75961 17.3906 9.56494 17.5853 9.56494 17.8254V19.5645C9.56494 19.8046 9.75961 19.9993 9.99973 19.9993C10.2399 19.9993 10.4345 19.8046 10.4345 19.5645V17.8254C10.4345 17.5853 10.2399 17.3906 9.99973 17.3906Z" fill="#FFD54F" />
      <path d="M19.565 9.56445H17.8259C17.5858 9.56445 17.3911 9.75913 17.3911 9.99925C17.3911 10.2394 17.5858 10.4341 17.8259 10.4341H19.565C19.8052 10.4341 19.9998 10.2394 19.9998 9.99925C19.9998 9.75909 19.8052 9.56445 19.565 9.56445Z" fill="#FFD54F" />
      <path d="M2.6087 9.99924C2.6087 9.75912 2.41402 9.56445 2.1739 9.56445H0.434796C0.194674 9.56449 0 9.75912 0 9.99924C0 10.2394 0.194674 10.434 0.434796 10.434H2.17394C2.41402 10.434 2.6087 10.2394 2.6087 9.99924Z" fill="#FFD54F" />
      <path d="M5.71023 3.441C5.78804 3.5758 5.93197 3.6587 6.08763 3.65838C6.16398 3.65866 6.23906 3.63857 6.30501 3.60013C6.51297 3.48005 6.58418 3.21417 6.46414 3.0062L5.59459 1.50013C5.47316 1.29299 5.20679 1.22349 4.99964 1.34491C4.79441 1.4652 4.72398 1.72811 4.84157 1.93489L5.71023 3.441Z" fill="#FFD54F" />
      <path d="M14.2894 16.5606C14.1679 16.3535 13.9016 16.284 13.6944 16.4055C13.4893 16.5258 13.4188 16.7887 13.5363 16.9954L14.4059 18.5015C14.4837 18.6363 14.6276 18.7192 14.7833 18.7189C14.8596 18.7192 14.9347 18.6991 15.0007 18.6606C15.2086 18.5406 15.2799 18.2747 15.1598 18.0667L14.2894 16.5606Z" fill="#FFD54F" />
      <path d="M16.7772 6.52137C16.8535 6.52165 16.9286 6.50156 16.9945 6.46312L18.5006 5.59357C18.7134 5.48225 18.7956 5.21955 18.6843 5.00677C18.573 4.794 18.3103 4.71175 18.0975 4.82306C18.0867 4.82873 18.0761 4.83484 18.0658 4.84141L16.5597 5.71096C16.3518 5.83104 16.2806 6.09697 16.4006 6.30489C16.4783 6.4394 16.6218 6.52227 16.7771 6.52227V6.52137H16.7772Z" fill="#FFD54F" />
      <path d="M3.00526 13.5358L1.49919 14.4054C1.29122 14.5255 1.22002 14.7913 1.34006 14.9993C1.41771 15.1338 1.56122 15.2167 1.71656 15.2167C1.79291 15.217 1.86799 15.1969 1.93394 15.1584L3.44001 14.2889C3.65278 14.1776 3.73504 13.9149 3.62372 13.7021C3.5124 13.4893 3.2497 13.4071 3.03693 13.5184C3.02609 13.524 3.01553 13.5302 3.00522 13.5367L3.00526 13.5358Z" fill="#FFD54F" />
      <path d="M1.49931 5.5944L3.00538 6.46395C3.07113 6.50223 3.14585 6.52236 3.22191 6.5222C3.46203 6.5222 3.65666 6.32753 3.65666 6.08741C3.65666 5.93211 3.5738 5.78859 3.43928 5.7109L1.93321 4.84135C1.73055 4.71254 1.46185 4.77238 1.33305 4.975C1.20424 5.17762 1.26408 5.44636 1.4667 5.57516C1.47701 5.58173 1.48757 5.58784 1.49841 5.59351L1.49931 5.5944Z" fill="#FFD54F" />
      <path d="M18.5008 14.4062L16.9947 13.5367C16.7921 13.4079 16.5234 13.4677 16.3946 13.6703C16.2658 13.8729 16.3256 14.1417 16.5282 14.2705C16.5385 14.277 16.5491 14.2831 16.5599 14.2888L18.066 15.1584C18.132 15.1968 18.207 15.2169 18.2834 15.2166C18.5235 15.2166 18.7181 15.0219 18.7181 14.7818C18.7181 14.6265 18.6353 14.483 18.5008 14.4053V14.4062H18.5008Z" fill="#FFD54F" />
      <path d="M13.6958 3.60005C13.7618 3.63848 13.8368 3.65858 13.9132 3.65829C14.0688 3.65862 14.2127 3.57567 14.2906 3.44092L15.1601 1.93484C15.2788 1.72611 15.2058 1.46067 14.997 1.34202C14.7902 1.2245 14.5274 1.2949 14.4071 1.50009L13.5375 3.00616C13.4173 3.21384 13.4882 3.47968 13.6958 3.60005Z" fill="#FFD54F" />
      <path d="M6.30451 16.4001C6.09655 16.2801 5.83067 16.3513 5.71059 16.5592L4.84103 18.0653C4.72099 18.2733 4.7922 18.5392 5.00016 18.6593C5.06616 18.6977 5.1412 18.7178 5.21754 18.7175C5.37317 18.7178 5.51713 18.6349 5.59495 18.5001L6.4645 16.994C6.58434 16.786 6.51284 16.5201 6.30476 16.4003C6.30468 16.4002 6.3046 16.4002 6.30451 16.4001Z" fill="#FFD54F" />
    </SvgIcon>;
};

export default ThemeIcon;