import AuthGuard from "components/authentication/AuthGuard"
import GuestGuard from "components/authentication/GuestGuard"
import DashboardLayout from "components/Layouts/DashboardLayout"
import LoadingScreen from "components/LoadingScreen"
import { lazy, Suspense } from "react"
import { Navigate } from "react-router-dom"

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  ) // Landing page

// Projects 페이지
const ProjectSearch = Loadable(
  lazy(() => import("./pages/projects/ProjectSearch"))
)
const ProjectDetails = Loadable(
  lazy(() => import("./pages/projects/ProjectDetails"))
)

// PM 페이지
const PmSearch = Loadable(lazy(() => import("./pages/pm/PmSearch")))
const PmDetails = Loadable(lazy(() => import("./pages/pm/PmDetails")))
const PmDataTable = Loadable(lazy(() => import("./pages/pm/PmDataTable")))

// PTM 페이지
const PtmSearch = Loadable(lazy(() => import("./pages/ptm/PtmSearch")))
const PtmDetails = Loadable(lazy(() => import("./pages/ptm/PtmDetails")))

// PSM 페이지
const PsmSearch = Loadable(lazy(() => import("./pages/psm/PsmSearch")))
const PsmDetails = Loadable(lazy(() => import("./pages/psm/PsmDetails")))

// 도구 페이지
const ToolsList = Loadable(lazy(() => import("./pages/tools/ToolsList")))
const ToolDetails = Loadable(lazy(() => import("./pages/tools/ToolDetails")))
const AddTool = Loadable(lazy(() => import("./pages/tools/AddTool")))

// TraceabilityPage
const TraceabilityPage = Loadable(lazy(() => import("./pages/Traceability")))

const LandingPage = Loadable(lazy(() => import("./pages/LandingPage")))
const ComponentsPage = Loadable(lazy(() => import("./pages/ComponentsPage")))
const PricingPage = Loadable(lazy(() => import("./pages/Pricing")))
const AboutPage = Loadable(lazy(() => import("./pages/About")))
const PrivacyPage = Loadable(lazy(() => import("./pages/Privacy")))
const ContactPage = Loadable(lazy(() => import("./pages/Contact"))) // authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")))
const Register = Loadable(lazy(() => import("./pages/authentication/Register")))
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
) // Dashboard pages

const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")))
const DashboardSales = Loadable(lazy(() => import("./pages/dashboards/Sales")))
const DashboardProjectManagement = Loadable(
  lazy(() => import("./pages/dashboards/ProjectManagement"))
)
const DashboardProjectManagementV2 = Loadable(
  lazy(() => import("./pages/dashboards/ProjectManagementV2"))
) // data tables

const DataTableV1 = Loadable(
  lazy(() => import("./pages/dataTable/DataTableV1"))
)
const DataTableV2 = Loadable(
  lazy(() => import("./pages/dataTable/DataTableV2"))
)

// account settings

const AccountSettings = Loadable(lazy(() => import("./pages/AccountSettings")))

// projects

const Calendar = Loadable(lazy(() => import("./pages/Calendar")))
const TodoList = Loadable(lazy(() => import("./pages/TodoList")))
const TeamMember = Loadable(lazy(() => import("./pages/TeamMember")))
const UkoProjectV1 = Loadable(
  lazy(() => import("./pages/ukoProjects/UkoProjectV1"))
)
const UkoProjectV2 = Loadable(
  lazy(() => import("./pages/ukoProjects/UkoProjectV2"))
)
const UkoProjectV3 = Loadable(
  lazy(() => import("./pages/ukoProjects/UkoProjectV3"))
)

const UserProfile = Loadable(lazy(() => import("./pages/UserProfile"))) // admin eCommerce

const OrderList = Loadable(
  lazy(() => import("./pages/adminEcommerce/OrderList"))
)
const ProductList = Loadable(
  lazy(() => import("./pages/adminEcommerce/ProductList"))
)
const CustomerList = Loadable(
  lazy(() => import("./pages/adminEcommerce/CustomerList"))
) // shop

const Shop = Loadable(lazy(() => import("./pages/shop")))
const Cart = Loadable(lazy(() => import("./pages/shop/Cart")))
const Payment = Loadable(lazy(() => import("./pages/shop/Payment")))
const PaymentSuccess = Loadable(
  lazy(() => import("./pages/shop/PaymentSuccess"))
) // invoice

const InvoiceList = Loadable(lazy(() => import("./pages/invoice/InvoiceList")))
const AddInvoice = Loadable(lazy(() => import("./pages/invoice/AddInvoice")))
const InvoiceDetails = Loadable(
  lazy(() => import("./pages/invoice/InvoiceDetails"))
) // user management

const UserList = Loadable(lazy(() => import("./pages/userManagement/UserList")))
const UserGrid = Loadable(lazy(() => import("./pages/userManagement/UserGrid")))
const AddNewUser = Loadable(
  lazy(() => import("./pages/userManagement/AddNewUser"))
) // chat

const Chat = Loadable(lazy(() => import("./pages/Chat"))) // error

const Error = Loadable(lazy(() => import("./pages/404"))) // routes

const routes = [
  {
    path: "/",
    element: <Navigate to="tools/ToolsList" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  // {
  //   path: "projects",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <ProjectSearch />,
  //     },
  //     {
  //       path: ":id",
  //       element: <ProjectDetails />,
  //     },
  //   ],
  // },
  {
    path: "traceability",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ":id",
        element: <TraceabilityPage />,
      },
    ],
  },
  {
    path: "pm",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PmSearch />,
      },
      {
        path: ":id",
        element: <PmDetails />,
      },
      {
        path: "data-table",
        element: <PmDataTable />,
      },
    ],
  },
  {
    path: "ptm",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PtmSearch />,
      },
      {
        path: ":id",
        element: <PtmDetails />,
      },
    ],
  },
  {
    path: "psm",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PsmSearch />,
      },
      {
        path: ":id",
        element: <PsmDetails />,
      },
    ],
  },
  {
    path: "tools",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "toolslist",
        element: <ToolsList />,
      },
      {
        path: "addtool",
        element: <AddTool />,
      },
      {
        path: ":id",
        element: <ToolDetails />,
      },
    ],
  },
  {
    path: "account-settings",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <AccountSettings />
        </DashboardLayout>
      </AuthGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardProjectManagementV2 />,
      },
      {
        path: "sales",
        element: <DashboardSales />,
      },
      {
        path: "project-management",
        element: <DashboardProjectManagement />,
      },
      {
        path: "project-management-v2",
        element: <DashboardProjectManagementV2 />,
      },
      {
        path: "data-table-v1",
        element: <DataTableV1 />,
      },
      {
        path: "data-table-v2",
        element: <DataTableV2 />,
      },
      {
        path: "account-settings",
        element: <AccountSettings />,
      },
      {
        path: "calendar",
        element: <Calendar />,
      },
      {
        path: "todo-list",
        element: <TodoList />,
      },
      {
        path: "uko-project-v1",
        element: <UkoProjectV1 />,
      },
      {
        path: "uko-project-v2",
        element: <UkoProjectV2 />,
      },
      {
        path: "uko-project-v3",
        element: <UkoProjectV3 />,
      },
      // {
      //   path: "project-details",
      //   element: <ProjectDetails />,
      // },
      {
        path: "team-member",
        element: <TeamMember />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "product-list",
        element: <ProductList />,
      },
      {
        path: "order-list",
        element: <OrderList />,
      },
      {
        path: "customer-list",
        element: <CustomerList />,
      },
      {
        path: "shop",
        element: <Shop />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
      {
        path: "payment-success",
        element: <PaymentSuccess />,
      },
      {
        path: "invoice-list",
        element: <InvoiceList />,
      },
      {
        path: "add-invoice",
        element: <AddInvoice />,
      },
      {
        path: "invoice-details",
        element: <InvoiceDetails />,
      },
      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "user-grid",
        element: <UserGrid />,
      },
      {
        path: "add-user",
        element: <AddNewUser />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "pricing",
        element: <PricingPage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
      {
        path: "privacy",
        element: <PrivacyPage />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
]
export default routes
